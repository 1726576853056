import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'api-down',
  templateUrl: './api-down.component.html',
  styleUrls: ['./api-down.component.scss']
})
export class ApiDownComponent implements OnInit{

  public title: string;
  public message: string;

  public constructor(
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    const userLanguage = navigator.language;
    // console.log('userLanguage', this.userLanguage);
    this.translate.setDefaultLang(userLanguage.slice(0,2));
    this.translate.use(userLanguage.slice(0,2))
  }
}
