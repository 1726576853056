import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  public constructor(private auth: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.toString().includes('authenticate')) {
      return next.handle(req)
    }

    if(req.url.toString().includes('oauth/authenticate_login')) {
      return next.handle(req)
    }
    // debugger;
    if(req.url.includes('refresh-token')) {
      // const requestClone = this.auth.addTokenHeader(req);
      // return next.handle(requestClone);
      return next.handle(req);
    }

    if(this.auth.isRefreshing) {
      // console.log('****REFRESH TOKEN EN PROCESO, SE CANCELA LA PETICION****');
      return EMPTY;
    }

    if(!req.url.toString().includes('assets') && !req.url.toString().includes('login-config') && !AuthService.getToken()) {
      this.auth.logout();
      // return EMPTY;
    }

    const requestClone = this.auth.addTokenHeader(req);
    return next.handle(requestClone)
  }

  
}
