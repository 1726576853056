import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {

  public APP_GREEN    = '#00AD62';
  public APP_YELLOW   = '#FFA82D';
  public APP_RED      = '#E53C38';
  public APP_UNKNOWN  = '#C2C2C2';

  public APP_GREEN_RGB    = [0, 173, 98];
  public APP_YELLOW_RGB   = [255, 168, 45];
  public APP_RED_RGB      = [229, 60, 56];
  public APP_UNKNOWN_RGB  = [194, 194, 194];

  private originalPrimaryColor   : string;
  private originalPrimaryColorHsl: number[];

  private prevPrimaryColorHsl    : number[];
  private prevPrimaryTextColorHsl: number[];

  private primaryColorHsl    : number[];
  private primaryTextColorHsl: number[];

  private cssRules: {selector: string, styles: {prop: string, value: any}[]}[] = [];
  private stylesToCheck: string[] = [
    'background', 'backgroundColor', 'borderColor',
    'borderTopColor', 'borderRightColor', 'borderBottomColor', 'borderLeftColor',
  ];

  // There are white colors hardcoded in CSSs, not used with a CSS variable (e.g.: $text-color)
  private whites: string[] = [
    "ghostwhite", "#fff", "#ffffff"
  ];

  // More hardcoded styles. TODO: fix those CSS and then un-hardcode this
  private colorOverrides: string[] = [
    '.f', 'div input', /*'div span',*/ '.ng-select .ng-arrow-wrapper .ng-arrow', 'div.right i', '.ui-dropdown-label',
    ':host::ng-deep .pi-chevron-down:before',
    '.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked',
    'vixion-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, vixion-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked, ng-select.multiselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, ng-select.multiselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked',
    '.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder',
    '.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value',
    'span.ui-calendar .ui-inputtext:hover',
    '.ui-datepicker:not(.ui-state-disabled) table td:not(.ui-state-disabled) a:not(.ui-state-active):not(.ui-state-highlight):hover',
    '.machine-states-bar span',
  ];

  private backgroundOverrides: string[] = [
    'vixion-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, vixion-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked, ng-select.multiselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, ng-select.multiselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked',
    'span.ui-calendar .ui-inputtext:hover',
    'ng-select.multiselect div.ng-value:hover',
  ];

  public darkTheme = false;
  
  public changeTheme(theme: string): void {
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

    if (theme.indexOf('dark') !== -1) {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }
  }

  public overrideTheme(): void {
    const layoutLink: HTMLLinkElement = document.getElementById('theme-css')  as HTMLLinkElement;
    const themeLink : HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;

    const themeOverride = document.getElementById('themeOverride');
    if (!!themeOverride) {
      if (this.primaryColorHsl !== this.prevPrimaryColorHsl
        || this.primaryTextColorHsl !== this.prevPrimaryTextColorHsl) {
        // Some or all colors have changed, so we have to remove the stylesheet and
        // add it back with only the required styles for this app
        const head: HTMLHeadElement = document.getElementsByTagName("HEAD")[0] as HTMLHeadElement;
        head.removeChild(themeOverride);

        if (!this.primaryColorHsl && !this.primaryTextColorHsl) {
          // Leave colors default then, no need to add a stylesheet if nothing was defined
          return;
        }
      } else {
        // Styles had already been overridden and will stay the same
        return;
      }
    }

    this.processThemedStyles([layoutLink.sheet, themeLink.sheet]);
    this.addCustomStyles();
    this.addStylesheet();
  }

  private addCustomStyles() {
    this.addCustomColors();
    this.addCustomBackgrounds();
  }

  private addCustomColors() {
    if (!this.primaryTextColorHsl) {
      return;
    }
    const textColor = this.hslStringify(this.primaryTextColorHsl);
    const darkGreyColor = '#424242';

    for (const cssRule of this.colorOverrides) {
      this.addRule(`div.topbar ${cssRule}`, 'color', `${textColor}`);
    }

    // More custom rules
    this.addRule(
      'div.topbar .ng-select .ng-arrow-wrapper .ng-arrow',
      'border-top-color',
      textColor
    );
    
    this.addRule(
      'div.topbar div input, div.topbar div input:focus, div.topbar div input:hover',
      'border-bottom-color',
      `${textColor} !important`
    );

    this.addRule(
      'div.topbar div input',
      'color',
      textColor
    );
    
    this.addRule(
      'div.topbar div input::placeholder',
      'color',
      `${this.hslStringify(this.darken(this.primaryTextColorHsl, 10))}`
    );
    
    this.addRule(
      'div.topbar .ui-datepicker table td > a.ui-state-highlight',
      'color',
      darkGreyColor
    );

    this.addRule(
      'div.topbar .ui-dropdown-panel .ui-dropdown-filter.ui-inputtext',
      'color',
      darkGreyColor
    );

    this.addRule(
      'div.topbar div.ng-dropdown-header > input',
      'color',
      darkGreyColor
    )
  }

  private addCustomBackgrounds() {
    if (!this.primaryColorHsl) {
      return;
    }
    const backgroundColor = this.hslStringify(this.primaryColorHsl);

    for (const cssRule of this.backgroundOverrides) {
      this.addRule(`div.topbar ${cssRule}`, 'background', `${backgroundColor} !important`);
    }

    this.addRule(
      '.ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):hover',
      'background',
      `${this.hslStringify(this.lighten(this.primaryColorHsl, 20))} !important`
    );

    this.addRule(
      '.layout-wrapper .topbar #menu-button:hover',
      'background',
      `${this.hslStringify(this.lighten(this.primaryColorHsl, 10))} !important`
    );

    this.addRule(
      'div.topbar div input::placeholder',
      'background',
      this.hslStringify(this.lighten(this.primaryColorHsl, 10))
    );

    this.addRule(
      'div.topbar .ui-datepicker:not(.ui-state-disabled) table td:not(.ui-state-disabled) a:not(.ui-state-active):not(.ui-state-highlight):hover',
      'background',
      this.hslStringify(this.lighten(this.primaryColorHsl, 15))
    );

    this.addRule(
      'div.topbar .ui-datepicker table td > a.ui-state-highlight',
      'background',
      '#ddd'
    );
  }

  private addRule(selector: string, prop: string, value: any) {
    this.cssRules.push({
      'selector': selector,
      'styles': [
        {
          'prop': prop,
          'value': value
        }
      ]
    });
  }

  private addStylesheet() {
    if (!this.cssRules || this.cssRules.length === 0) {
      return;
    }

    const head     : HTMLHeadElement  = document.getElementsByTagName("HEAD")[0] as HTMLHeadElement;
    const styleElem: HTMLStyleElement = document.createElement("style") as HTMLStyleElement;
    styleElem.id = 'themeOverride';
    styleElem.innerHTML = '';

    for (const rule of this.cssRules) {
      let ruleBody = "";

      for (const p of rule.styles) {
        ruleBody += `${p.prop}: ${p.value};\n`
      }

      styleElem.innerHTML += `${rule.selector} {
        ${ruleBody}
      }\n\n`;
    }
    head.appendChild(styleElem);
  }

  private hslStringify(hsl: number[]):string {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
  }

  public findCssRulesBySelector(selector: string, sheets?:any): CSSStyleRule[] {
    if (!sheets) {
      sheets = document.styleSheets;
    }

    const result: CSSStyleRule[] = [];
    for (const s in sheets) {
      const styleSheet: any = sheets[s];

      if (!(styleSheet instanceof CSSStyleSheet)) {
        // Might end up doing `sheets['length']` as length is a property, so skip that
        continue;
      }

      let cssRules;
      try {
        // Test for read access to this property
        cssRules = styleSheet.cssRules;
      } catch (e) {
        // External CSSs like Google's font stylesheet don't allow accessing its rules
      }
      if (!cssRules) {
        continue;
      }

      for (const c in cssRules) {
        const rule: any = cssRules[c];

        if (!(rule instanceof CSSStyleRule)) {
          continue;
        }

        if (rule.selectorText.startsWith(selector)) {
          result.push(rule);
        }
      }
    }
    return result;
  }

  private processThemedStyles(sheets?) {
    this.cssRules.length = 0;

    const rules = this.findCssRulesBySelector('', sheets);

    for (const rule of rules) {
      const styles: {prop: string, value: any}[] = [];
      
      if (!!this.primaryColorHsl && this.primaryColorHsl.length > 0) {
        for (const s of this.stylesToCheck) {
          const style = rule.style[s];
          if (!style) {
            continue;
          }
          
          let newHslStyle: number[];
          if (style === this.originalPrimaryColor) {
            newHslStyle = this.primaryColorHsl;
          } else {
            const hsl = this.colorToHsl(style);
            if (this.areSimilarColors(hsl, this.originalPrimaryColorHsl)) {
              const newLightness = this.primaryColorHsl[2] + (hsl[2] - this.originalPrimaryColorHsl[2]);
              newHslStyle = [this.primaryColorHsl[0], this.primaryColorHsl[1], newLightness];
            } else {
              continue;
            }
          }

          const roundedHslColor: number[] = newHslStyle.map(v => Math.round(v));
          const dashCaseProp = s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          styles.push({prop: dashCaseProp, value: this.hslStringify(roundedHslColor)});

          if (newHslStyle[2] < 35 && (s === 'backgroundColor' || s === 'background')) {
            // This is quite dark of a background, so we make the text white
            styles.push({prop: 'color', value: 'ghostwhite !important'});
          }
        }
      }

      if (!!this.primaryTextColorHsl && this.primaryTextColorHsl.length > 0) {
        const color = rule.style.color;
        if (!!color) {
          let newHslColor: number[];
          if (color === this.originalPrimaryColor) {
            newHslColor = this.primaryTextColorHsl;
          } else if (this.whites.includes(color)) {
            newHslColor = this.primaryTextColorHsl;
          } else {
            const hsl = this.colorToHsl(color);
            if (!!hsl && hsl[2] >= 98) {
              // It has so much lightness that it's basically white, so we're replacing this color as well
              newHslColor = this.primaryTextColorHsl;
            }
            // TODO: get the primary text color and replace it as well. Problem: text colors are mostly
            // hardcoded and the $primaryTextColor variable is barely used
          }
          if (newHslColor) {
            styles.push({prop: 'color', value: this.hslStringify(newHslColor)});
          }
        }
      }

      if (!styles || styles.length === 0) {
        continue;
      }

      let newSelector: string;
      if (rule.selectorText.includes('.topbar')) {
        newSelector = rule.selectorText;
      } else {
        // FIXME: dropdown panels are spawned outside of the <app-main>, and here we're just applying
        // the styles to every dropdown, but if it's not triggered from the header I guess we shouldn't do this
        newSelector = rule.selectorText.split(', ').map(s => s.replace(/^body\s/, '.dropdownOverlay ')).join(', ');
        this.cssRules.push({'selector': newSelector, 'styles': styles});

        // When SCSS rules are compiled, they are preppended with 'body, so we replace it with
        // the topbar so have more specificity (higher priority) and so that it only affects inside that
        newSelector = rule.selectorText.split(', ').map(s => s.replace(/^body\s/, 'div.topbar ')).join(', ');
      }
      this.cssRules.push({'selector': newSelector, 'styles': styles});
    }
  }

  private areSimilarColors(a, b): boolean {
    if (!a || !b) {
      return false;
    }

    if (Math.abs(a[0] - b[0]) > 1) {
      // Hue diff
      return false;
    }

    if (Math.abs(a[1] - b[1]) > 1) {
      // Saturation diff
      return false;
    }

    if (Math.abs(a[2] - b[2]) > 80) {
      // Lightness diff
      // This threshold is here for no particular reason, just thinking about
      // extreme cases like white vs dark grey, they are not really similar colors
      return false;
    }

    return true;
  }

  // Convert whatever color to RGBA relying on the web browser, then to HSL.
  // This allows us to lighten or darken colors easily the same way SCSS does
  public colorToHsl(color: any): number[] {
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = 1;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 1, 1);

    // Detect invalid values
    ctx.fillStyle = '#000';
    ctx.fillStyle = color;
    const computed = ctx.fillStyle;
    ctx.fillStyle = '#fff';
    ctx.fillStyle = color;
    if (computed !== ctx.fillStyle) {
      return; // invalid color
    }

    ctx.fillRect(0, 0, 1, 1);

    const rgba = [...ctx.getImageData(0, 0, 1, 1).data];

    return this.rgbToHsl(rgba[0], rgba[1], rgba[2]);
  }

  private rgbToHsl(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max: number = Math.max(r, Math.max(g, b));
    const min: number = Math.min(r, Math.min(g, b));
    const dt : number = max - min;

    const lightness: number = 50 * (max + min);

    let saturation = 0;
    let hue = 0;

    if (max === min) {
      hue = 0;
    } else if (max === r) {
      hue = (60 * (g - b) / dt) % 360;
    } else if (max === g) {
      hue = (120 + 60 * (b - r) / dt) % 360;
    } else if (max === b) {
      hue = (240 + 60 * (r - g) / dt) % 360;
    }

    if (max === min) {
      saturation = 0;
    } else if (lightness < 50) {
      saturation = 100 * dt / (max + min);
    } else {
      saturation = 100 * dt / (2 - max - min);
    }

    return [hue, saturation, lightness];
  }

  private lighten(hsl: number[], percent: number) {
    return [hsl[0], hsl[1], Math.min(100, hsl[2] + percent)];
  }

  private darken(hsl: number[], percent: number) {
    return [hsl[0], hsl[1], Math.max(0, hsl[2] - percent)];
  }

  public setOriginalPrimaryColors(cssRule?: CSSStyleRule):void {
    this.originalPrimaryColor    = cssRule.style.backgroundColor;
    this.originalPrimaryColorHsl = this.colorToHsl(this.originalPrimaryColor);
  }

  public setPrimaryColors(color: number[], textColor: number[]):void {
    this.primaryColorHsl     = color;
    this.primaryTextColorHsl = textColor;
  }

  public setPrevPrimaryColors(color: number[], textColor: number[]):void {
    this.prevPrimaryColorHsl     = color;
    this.prevPrimaryTextColorHsl = textColor;
  }

  // Find the corresponding app color, the one used in the rest of the app
  // At the moment only supports green, yellow and red, usually for
  // OK/warning/error kind of stuff, so you pass a color that is yellow-ish
  // and it returns the official yellow color of the app
  public getAppColor(color: string): string {
    const hsl = this.colorToHsl(color);
    let result: string;

    if (hsl) {
      if ((hsl[0] < 25 || hsl[0] > 260) && hsl[1] > 0) {
        result = this.APP_RED;
      } else if (hsl[0] > 65) {
        result = this.APP_GREEN;
      } else if (hsl[0] > 25 && hsl[0] < 65) {
        result = this.APP_YELLOW;
      } else {
        result = this.APP_UNKNOWN;
      }
    }
    return result;
  }

  /**
   * @param appColor one in ('green', 'yellow', 'red', 'unknown')
   * @param opacity a number between 1 and 0
   * @returns the app color with desired opacity
   */
  public getAppColorWithOpacity(appColor: string, opacity: number): string {
    let rgbaColor = 'rgba('+this.APP_UNKNOWN_RGB.join(',')+', '+ opacity+')';
    if (appColor.toLowerCase() === 'green') {
      rgbaColor = 'rgba('+this.APP_GREEN_RGB.join(',')+', '+ opacity+')';
    } else if (appColor.toLowerCase() === 'yellow') {
      rgbaColor = 'rgba('+this.APP_YELLOW_RGB.join(',')+', '+ opacity+')';
    } else if (appColor.toLowerCase() === 'red') {
      rgbaColor = 'rgba('+this.APP_RED_RGB.join(',')+', '+ opacity+')';
    } else if (appColor.toLowerCase() === 'unknown') {
      rgbaColor = 'rgba('+this.APP_UNKNOWN_RGB.join(',')+', '+ opacity+')';
    }
    return rgbaColor;
  }

}
