import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import {EventSourcePolyfill} from "ng-event-source";
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

import { environment } from '../../../environments/environment';

import { Frequency } from '../interfaces/frequency.enum';
import { Variable } from '../interfaces/variable.interface';
import { Filter } from '../interfaces/filter.interface';
import { Status, WidgetStatus } from '../interfaces/widget-status.interface';
import { WidgetResponse } from '../interfaces/widget-response.interface';
import { TranslateService } from '@ngx-translate/core';
import { Program } from '../interfaces/program.interface';
import { Annotation } from '../interfaces/annotation.interface';

interface ArgumentsGetWidgetData {
  appName: string, 
  idView: number, 
  entitiIds: string, 
  idWidget: number, 
  date: Date, 
  frequency: Frequency,
  shift?: number,
  filters: Filter[], 
  responseType?: string, 
  entityType?: string,
  contentType?: string
}

@Injectable()
export class DataService {
  private headers;
  private http: HttpClient;

  public constructor(
    http: HttpClient,
    private translateService: TranslateService,
    private auth: AuthService,
    private ngZone: NgZone) {
    this.http = http;
  }

  private isTokenExpiredError(error: any): boolean {
    return error.status === 401;
  }

  public getWidgetDataSSE(appName: string, idView: number, entityIds: string, idWidget: number, date: Date, frequency: Frequency,
    filters: Filter[] = [], entityType = "machine"): Observable<any> {

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = this.generateParams(frequency, date, filters, timezone);
    const urlParams = params.toString();

    return from(this.auth.getTokenAsync()).pipe(
      switchMap(token => {
        return this.connectEventSource(token, appName, idView, entityIds, idWidget, urlParams, entityType).pipe(
          catchError(error => {
            if (this.isTokenExpiredError(error)) {
              return this.auth.refreshToken().pipe(
                switchMap(newTokenResponse => {
                  this.auth.setToken(newTokenResponse.token);
                  return this.connectEventSource(newTokenResponse.token, appName, idView, entityIds, idWidget, urlParams, entityType);
                })
              );
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  }

  private connectEventSource(token: string, appName: string, idView: number, entityIds: string, idWidget: number, urlParams: string, entityType: string): Observable<any> {
    const headers = {
      'Authorization': `Bearer ${token}`
    };
    const url = `${environment.apiV2Url}/${appName}/view/${idView}/${entityType}/${entityIds}/widget/${idWidget}?${urlParams}`;

    return new Observable(observer => {
      let eventSource = new EventSourcePolyfill(url, { headers, heartbeatTimeout: 150000 });

      eventSource.onmessage = event => {
        this.ngZone.run(() => {
          const data = JSON.parse(event.data);
          observer.next(data);
        });
      };

      eventSource.onerror = error => {
        this.ngZone.run(() => {
          // console.error('EventSource error:', error);
          if (eventSource.readyState === EventSource.CLOSED) {
              observer.complete();
          }
        });
      };

      // Manejamos la limpieza cuando se unsubscribe
      return () => {
        eventSource.close();
      };
    });
  }

  /**
   * Get widget data
   * @param appName App name
   * @param idView View id
   * @param entitiIds Entity ids joined by '+'
   * @param idWidget Widget id
   * @param variable Variable name
   * @param start Start date
   * @param end End date
   */
  public getWidgetData(appName: string, idView: number, entitiIds: string, idWidget: number, date: Date, frequency: Frequency,
    filters: Filter[] = [], responseType: string = null, entityType="machine"): Observable<any> {

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = this.generateParams(frequency, date, filters, timezone);
    return responseType
      ? this.http.get(
        `${environment.apiV2Url}/${appName}/view/${idView}/${entityType}/${entitiIds}/widget/${idWidget}`,
        { responseType: 'text', params: params }
      )
      : this.http.get(
        `${environment.apiV2Url}/${appName}/view/${idView}/${entityType}/${entitiIds}/widget/${idWidget}`,
        { params: params }
      )
  }

  /**
   * Get widget data
   * @param appName App name
   * @param idView View id
   * @param entitiIds Entity ids joined by '+'
   * @param idWidget Widget id
   * @param variable Variable name
   * @param start Start date
   * @param end End date
   */
  public getWidgetDataNamedParams({appName, idView, entitiIds, idWidget, date, frequency, shift, filters = [], responseType = null, entityType = "machine", contentType = null}: ArgumentsGetWidgetData): Observable<any> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = this.generateParams(frequency, date, filters, timezone, shift);
    
    const options = {};
    options['params'] = params;

    if (responseType) options['responseType'] = responseType;

    if(contentType) {
      this.headers = new HttpHeaders({ 'Content-Type': contentType });
      options['headers'] = this.headers;
    } 

    return this.http.get(`${environment.apiV2Url}/${appName}/view/${idView}/${entityType}/${entitiIds}/widget/${idWidget}`, options);
  }

  /**
   * Get signal data by date range
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idWidget Widget id
   * @param variable Variable name
   * @param start Start date
   * @param end End date
   */
  public getSignalData(appName: string, idView: number, idEntity: number, idWidget: number, variable: Variable, start: Date, end: Date): Observable<any> {

    const params = new HttpParams()
      .set('start', start.getTime().toString())
      .set('end', end.getTime().toString())
      .set('name', variable.internalName);

    return this.http.get(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { params: params }
    );
  }

  /**
   * Get data by program
   * @param idEntities Entity id
   * @param date Current date
   * @param frequency Start date
   */
  public getSubProgramsData(idEntities: number[], date: Date, frequency: Frequency): Observable<any> {
    const dateStr = this.dateToString(date);
    const params = new HttpParams()
      .set('entity', idEntities.toString())
      .set('frequency', frequency.toString())
      .set('date', dateStr);

    return this.http.get(
      `${environment.apiV2Url}/subprograms`,
      { params: params }
    );
  }

  /**
   * Get data by program
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idWidget Widget id
   * @param variable Variable name
   * @param start Start date
   * @param end End date
   * @param program Program
   */
  public getProgramData(appName: string, idView: number, idEntity: number, idWidget: number, date: Date, frequency: Frequency, program: Program | any): Observable<any> {

    const dateStr = this.dateToString(date);
    const params = new HttpParams()
      .set('frequency', frequency.toString())
      .set('date', dateStr)
      .set('idProgram', program.start);

    return this.http.get(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { params: params }
    );
  }

  /**
   * Get signals data by date range
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idWidget Widget id
   * @param variables Variables array
   * @param start Start date
   * @param end End date
   */
  public getSignalsData(appName: string, idView: number, idEntity: number, idWidget: number, variables: any[], start: Date, end: Date, timezone = "Europe/Madrid"): Observable<any> {

    const idVariables = variables.map((v): any => v.n).join(',');

    const params = new HttpParams()
      .set('start', start.toString())
      .set('end', end.toString())
      .set('variables', idVariables)
      .set('timezone', timezone);

    return this.http.get(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { params: params }
    );
  }

  /**
   * Get data by program
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idWidget Widget id
   * @param variable Variable name
   * @param start Start date
   * @param end End date
   * @param program Program
   */
  public getToolData(appName: string, idView: number, idEntity: number, idWidget: number, date: Date, frequency: Frequency, aliasVariables: any[]): Observable<any> {

    const dateStr = this.dateToString(date);
    const params = new HttpParams()
      .set('frequency', frequency.toString())
      .set('date', dateStr)
      .set('aliasVariables', aliasVariables.join(', '));

    return this.http.get(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { params: params }
    );
  }

  /**
   * Get data by part number
   * @param partNumber Part number id
   */
  public getPartNumberData(partNumber: number): Observable<any> {
    return this.http.get(
      `${environment.apiV2Url}/workTime/partNumber/${partNumber}`
    );
  }

  /**
   * Get children data for a work time item
   */
  public getWorkTimeChildren(filters: Filter[]): Observable<any> {
    const params = this.generateParams(null, null, filters);
    return this.http.get(
      `${environment.apiV2Url}/workTime/children`,
      { params }
    );
  }

  /**
   * Delete annotation
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idWidget Widget id
   * @param annotation Annotation
   */
  public deleteAnnotation(appName: string, idView: number, idEntity: number, idWidget: number, annotation: Annotation | any, frequency: Frequency, date: Date): Observable<any> {

    const dateStr = this.dateToString(date);
    const params = new HttpParams()
      .set('frequency', frequency.toString())
      .set('date', dateStr)
      .set('intervalStart', annotation.intervalStart)
      .set('seriesName', annotation.seriesName);

    return this.http.delete(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { params: params }
    );
  }

  /**
   * Update annotation
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idWidget Widget id
   * @param annotation Annotation
   */
  public updateAnnotation(appName: string, idView: number, idEntity: number, idWidget: number, annotation: Annotation | any, frequency: Frequency, date: Date): Observable<any> {

    const dateStr = this.dateToString(date);
    return this.http.put(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { annotation, frequency: frequency.toString(), date: dateStr }
    );
  }

  /**
   * Update annotation
   * @param appName App name
   * @param idView View id
   * @param idEntity Entity id
   * @param idFrequency Frequency id
   * @param idWidget Widget id
   * @param annotation Annotation
   */
  public addAnnotation(appName: string, idView: number, idEntity: number, frequency: Frequency, idWidget: number, annotation: Annotation | any, date: Date): Observable<any> {

    const dateStr = this.dateToString(date);
    return this.http.post(
      `${environment.apiV2Url}/${appName}/view/${idView}/machine/${idEntity}/widget/${idWidget}`,
      { annotation, frequency: frequency.toString(), date: dateStr }
    );
  }

  /**
   * Get data by program
   * @param {number} idEntity Entity id
   * @param {number} from Start timestamp
   * @param {number} to End timestamp
   * @param {number} downsampling Time delta. 1 sample each N seconds
   * @param {string} variable Signal name
   */
  public get3DData(
    idEntity: number,
    from: number,
    to: number,
    downsampling: number,
    variable: string,
  ): Observable<any> {

    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('downsampling', downsampling.toString())
      .set('variable', variable.toString())
      .set('idEntity', idEntity.toString())
      .set('filter', '');

    return this.http.get(
      `${environment.apiV2Url}/toolpath3d`,
      { params: params }
    );
  }

  public getFiltered3DData(
    idEntity: number,
    from: number,
    to: number,
    downsampling: number,
    variable: string,
    filter: string,
  ): Observable<any> {

    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('downsampling', downsampling.toString())
      .set('variable', variable.toString())
      .set('idEntity', idEntity.toString())
      .set('filter', filter.toString());

    return this.http.get(
      `${environment.apiV2Url}/toolpath3d`,
      { params: params }
    );
  }

  /**
   * Transform a Date into YYYYMMDD format string
   * @param date Date to transform
   */
  public dateToString(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    const day = ("0" + (date.getDate())).slice(-2);
    const dateStr = `${year}${month}${day}`;
    return dateStr;
  }

  /**
   * Transform a date string in YYYYMMDD format to Date
   * @param date Date string in YYYYMMDD format
   */
  public stringToDate(date: string): Date {
    if (date == null || date.length != 8) {
      throw new Error(this.translateService.instant("common.invalid-date-format"));
    }
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(4, 6));
    const day = Number(date.substring(6, 8));
    return new Date(year, month - 1, day);
  }

  /**
   * Transform the API Response into a widget status
   */
  public responseToStatus(response: WidgetResponse<any>): WidgetStatus {

    if (response == null || response.statusCode == null) {
      return {
        message: this.translateService.instant("common.invalid-response"),
        status: Status.Error,
      }
    }
    switch (response.statusCode) {
      case 0:
        // Ok
        return {
          message: '',
          status: Status.Ok,
        }

      case 1:
        // Error
        return {
          message: response.message,
          status: Status.Error,
        }

      case 2:
        // No valid license
        return {
          message: this.translateService.instant("common.no-valid-license"),
          status: Status.Error,
        }

      case 3:
        // No data for this period
        return {
          message: response.message,
          status: Status.NoData,
        }

      default:
        // Unknown status code
        return {
          message: this.translateService.instant("common.invalid-response"),
          status: Status.Error,
        }
    }

  }

  private generateParams(frequency: Frequency, date: Date, filters: Filter[]=[], timezone: string = null, shift = null): HttpParams {
    let params = new HttpParams();
    if (frequency && date) {
      const dateStr = this.dateToString(date);
      params = params.set('frequency', frequency.toString())
      params = params.set('date', dateStr);
    }

    if (timezone) {
      params = params.set('timezone', timezone);
    }
    
    if (shift || shift === 0) {
      params = params.set('shift', shift);
    }

    filters.forEach((filter: Filter): void => {
      params = params.append(filter.key, filter.value);
    });

    return params;
  }

  /**
   * Get pending incidents data
   * @param appName App name
   * @param idView View id
   * @param idWidget Widget id
   */
  public getPendingIncidents(appName: string, idView: number, idWidget: number): Observable<any> {

    // const params = this.generateParams(frequency, date, filters);
    return this.http.get(
      `${environment.apiV2Url}/${appName}/view/${idView}/widget/${idWidget}/pending`,
      // { params: params }
    )
  }

  /**
   * Get pending incidents data
   * @param appName App name
   * @param idView View id
   * @param idWidget Widget id
   */
  public justifyIncident(idEntity: number, idIncidentCause: number, start: number, details: string): Observable<any> {

    // const params = this.generateParams(frequency, date, filters);
    return this.http.put(
      `${environment.apiV2Url}/operator/justify/incident`,
      { idEntity, idIncidentCause, start, details }
    )
  }

}
