import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

import { AuthService } from './core/services/auth.service';
import { Message } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public $isApiDown: Observable<boolean>;
  public $tokenAccessError: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private authService: AuthService
  ) {
    this.translate.setDefaultLang('en');
    this.$isApiDown = this.authService.isApiDown();
    this.$tokenAccessError = this.authService.isTokenAccessError();
  }
}
