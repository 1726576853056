export enum Status {
  Loading = "loading",
  NoEntity = "noEntity",
  Error = "error",
  NoLicense = "noLicense",
  NoData = "noData",
  Ok = "ok",
  NoItem = "noItem"
} 
export interface WidgetStatus {
  message: string;
  status: Status;
}