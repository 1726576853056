import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private auth: AuthService
  public constructor(auth: AuthService, private router: Router) { 
    this.auth = auth;
  }
  public canActivate(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  public canActivateChild(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  public canLoad(): Observable<boolean> {
    const queryParams = this.router.getCurrentNavigation().extractedUrl.queryParams
    if(queryParams && queryParams.client_token) {
      this.auth.setToken(queryParams.client_token);
      return this.auth.ssoLogin();
    } else {
      return this.checkLoginOrLogout();
    }
  }

  private checkLoginOrLogout(): Observable<boolean> {
    return this.auth.isLoggednIn().pipe(tap((res): boolean => {
      if (!res) {
        this.auth.logout();
      }
      return res;
    }));
  }
}
