import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng/primeng';
import { AuthService } from './../core/services/auth.service';
import { ThemeService } from './../core/services/theme.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';

export interface LoginConfig {
  "theme": string;
  "welcomeText": string;
  "title": string;
  "subtitle": string;
  "background": string;
  "headerIcon": string;
  "accentColor": string;
}
@Component({
  selector: 'app-token-access-error',
  templateUrl: './token-access-error.component.html',
  styleUrls: ['./token-access-error.component.scss']
})
export class TokenAccessErrorComponent implements OnInit {
  protected inputEmail: string;
  protected inputPassword: string;

  public config: LoginConfig;
  public msgs: Message[] = [];

  private defaultBackgroundUrl = "assets/images/drill_background.png";

  public constructor(
    private authService: AuthService,
    private themeService: ThemeService
  ) {}

  public async ngOnInit(): Promise<any>{
    // call service for get loginconfig
    this.authService.getTokenAccessErrorMessage().subscribe(res => {
      this.msgs.push({ severity: 'error', summary: 'Authentication error: ', detail: res });
    });
    await this.authService.getLoginConfig(window.location.hostname).subscribe((config: LoginConfig): void => {
      this.themeService.changeTheme(config.theme);
      this.config = config;
    });
  }

  private getBackgroundUrl(): string {
    return (this.config && this.config.background) ? this.config.background : this.defaultBackgroundUrl;
  }

  public getBodyStyle(): any {
    return {
      "background-image": `url("${this.getBackgroundUrl()}")`
    };
  }

}
